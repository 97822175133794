









































































import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import ServiceNoteCreateDialog from "@/components/dialogs/ServiceNoteCreateDialog.vue";
import ServiceNoteUpdateDialog from "@/components/dialogs/ServiceNoteUpdateDialog.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { ServiceNoteModel, ServiceNoteEvidenceModel } from "@/api/generated";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component({ components: { ServiceNoteCreateDialog, ServiceNoteUpdateDialog } })
export default class ServiceNotes extends Vue {
  private serviceId = "";
  private serviceNoteId = "";
  private updateDialog = false;
  private createDialog = false;
  private loading = false;
  private items: Array<ServiceNoteModel> = [];
  private noteEvidence: Array<ServiceNoteEvidenceModel> = [];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    this.serviceId = this.$route.params.serviceId;
    await this.getServiceNotes();
  }

  private async getServiceNotes() {
    try {
      this.loading = true;
      const response = await Api.ServiceNoteService.apiServicenoteServiceServiceIdGet(
        this.serviceId
      );
      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch service notes");
    } finally {
      this.loading = false;
    }
  }

  private onCreateNote() {
    this.createDialog = true;
  }

  private async onDeleteNote(item: ServiceNoteModel) {
    if (!item.serviceNoteId) {
      return;
    }

    try {
      this.loading = true;
      await Api.ServiceNoteService.apiServicenoteServiceNoteIdDelete(
        item.serviceNoteId
      );
      snackbarModule.setSnackbarMessage("Note deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete note");
    } finally {
      this.loading = false;
    }

    await this.getServiceNotes();
  }

  private onUpdateNote(item: ServiceNoteModel) {
    if (!item.serviceNoteId) {
      return;
    }

    this.serviceNoteId = item.serviceNoteId;
    this.updateDialog = true;
  }

  private async onDownloadEvidence(evidence: ServiceNoteEvidenceModel) {
    if (!evidence.documentId) {
      return;
    }

    const fileResponse = await Api.DocumentService.apiDocumentDocumentIdGet(
      evidence.documentId
    );
    if (evidence.fileName != null) {
      FileDownloadUtility.DownloadFile(fileResponse.data, evidence.fileName);
    }
  }
}

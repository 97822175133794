













































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { ServiceNoteUpdateModel } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class ServiceNoteUpdateDialog extends Validation {
  @Prop(String) private serviceNoteId!: string;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: ServiceNoteUpdateModel = {
    title: "",
    note: ""
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    try {
      const response = await Api.ServiceNoteService.apiServicenoteServiceNoteIdGet(
        this.serviceNoteId
      );
      this.model = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch service note");
      this.syncedDialog = false;
    }
  }

  private async onNoteUpdate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.ServiceNoteService.apiServicenoteServiceNoteIdPut(
        this.serviceNoteId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Note updated");
      this.$emit("note-updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update note");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}




























































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import ServiceNoteCreateModel from "@/models/serviceNoteCreateModel";
import FormDataFactory from "@/utility/formDataFactory";
import { apiClient } from "@/services/apiService";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class ServiceNoteCreateDialog extends Validation {
  @Prop(String) private serviceId!: string;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;
  private files: Array<File> = [];
  private loading = false;
  private model: ServiceNoteCreateModel = {
    serviceId: "",
    title: "",
    note: "",
    evidence: null,
    fileName: ""
  };

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private async onNoteCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.model.serviceId = this.serviceId;
      this.loading = true;
      this.model.evidence = this.files;
      const formData = FormDataFactory.Create(this.model);
      await apiClient.post("api/serviceNote", formData);
      snackbarModule.setSnackbarMessage("Note created");
      this.$emit("note-created");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create note");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
